<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>评论管理</el-breadcrumb-item>
        <el-breadcrumb-item>评论信息</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">商品名称：</div>
                <el-input v-model="search.commodityName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">订单商品ID：</div>
                <el-input v-model="search.orderCommodityId" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="orderCommodityId" label="订单商品ID" min-width="120"></el-table-column>
            <el-table-column align="center" prop="commodityName" label="商品名称" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="evaluation" label="评价" min-width="120">
              <template slot-scope="scope">
                <span class="contennt-desc">{{scope.row.evaluation}}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="rating" label="评分"></el-table-column>
            <el-table-column align="center" label="是否有图">
              <template slot-scope="scope">
                {{ scope.row.pictureStatus | getPictureStatus }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="反馈图片" min-width="200">
              <template slot-scope="scope">
                <div v-if="scope.row.evaImages">
                  <img class="icon-img"
                       v-for="(item,index) in scope.row.evaImages"
                       :key="index"
                       @click="handleSee(item)"
                       :src="item"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="评价人姓名" min-width="60"></el-table-column>
            <el-table-column align="center" prop="createDate" label="评论时间" min-width="120"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人" min-width="60"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
<!--                  <div class="border_center_left" v-if="evaluationDel" @click="del(scope.row.id)">删除</div>-->
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title="详情"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
    >
      <el-form :model="upData" ref="ruleForm" label-position="right"
               label-width="140px">
        <el-form-item label="订单商品ID：">{{ upData.orderCommodityId }}</el-form-item>
        <el-form-item label="商品名称：">{{ upData.commodityName }}</el-form-item>
        <el-form-item label="评价：">{{ upData.evaluation }}</el-form-item>
        <el-form-item label="评价人姓名：">{{ upData.createUserName }}</el-form-item>
        <el-form-item label="评价时间：">{{ upData.createDate }}</el-form-item>
        <el-form-item label="是否有图：">{{ upData.pictureStatus | getPictureStatus }}</el-form-item>
        <el-form-item label="评价图片：" prop="advertiseImage" v-if="upData.pictureStatus===2">
          <div class="flexRow">
            <div class="up-image">
              <div class="image-list flexRow">
                <img v-for="(item, index) in upData.evaImages" :key="index" @click="handleSee(item)" :src="item"
                     alt/>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    return {
      search: {
        commodityName: "",
        orderCommodityId: ""
      },
      type: "",
      pictureStatus: [
        {label: '无', value: 1},
        {label: '有', value: 2}
      ],
      upData: {},
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      formDataReady: true,
      tableLoading: false,
      btnLoading: false,
      dialogImgUrl: "",
      evaluationDel: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  // 过滤器需要写在组件之上
  filters: {
    getPictureStatus(value) {
      // _this 在全局设置 let _this, 在data 里面赋值 _this: this;
      const data = _this.pictureStatus.filter(item => item.value == value);
      // 确保有值
      if (data && data.length > 0) {
        return data[0].label;
      }
    }
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  watch: {
    type: function (val) {
      // console.log(val)
    },
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      // _this.tableList.push({
      //   createDate: "2021-08-26 14:00:00",
      //   createUserName: "admin",
      //   commodityName: "商品意见反馈一般首先描述一下大致情况，然后是具体意见。意见分点逐条写比较明确，可以先逐条写缺陷和方法写在一起。",
      //   evaluation: "评价意见反馈一般首先描述一下大致情况，然后是具体意见。意见分点逐条写比较明确，可以先逐条写缺陷和方法写在一起。",
      //   evaImages: "https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png",
      //   commodityId: "15681776845",
      //   orderCommodityId: "sdf1564615681776845",
      //   rating: 5,
      //   pictureStatus: 2,
      //   id: "1",
      //   updateDate: "",
      //   updateUserName: "admin"
      // },{
      //   createDate: "2021-08-26 14:00:00",
      //   createUserName: "admin",
      //   commodityName: "商品意见反馈一般首先描述一下大致情况，然后是具体意见。意见分点逐条写比较明确，可以先逐条写缺陷和方法写在一起。",
      //   evaluation: "评价意见反馈一般首先描述一下大致情况，然后是具体意见。意见分点逐条写比较明确，可以先逐条写缺陷和方法写在一起。",
      //   evaImages: "https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png",
      //   commodityId: "15681776845",
      //   orderCommodityId: "sdf1564615681776845",
      //   rating: 4,
      //   pictureStatus: 1,
      //   id: "2",
      //   updateDate: "",
      //   updateUserName: "admin"
      // });
      // _this.tableList.forEach(item => {
      //   item.evaImages = item.evaImages.split(',');
      // });
      // return false;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }
      _this.$api
          .getEvaluation(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableList.forEach(item => {
                item.evaImages = item.evaImages.split(',');
              });
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "evaluation:del") {
          _this.evaluationDel = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    edit() {
      let _this = this;
      _this.tableLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.$api.editFeedbackInfo(_this.upData).then(res => {
            if (res.data.code == 200) {
              _this.tableLoading = false;
              _this.$message.success("修改成功");
              _this.getData();
              _this.handleClose();
            }
          });
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        commodityName: "",
        orderCommodityId: ""
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delEvaluation({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.advertiseImage = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.advertiseImage = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.advertiseImage = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
</style>
